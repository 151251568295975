import React from 'react'
import { useContext, useState, useEffect } from 'react'
import { UserContext } from '../UserContext'
import axios from 'axios'
function AccountSettings() {


    const { isLoggedIn, setIsLoggedIn, username, setUsername } = useContext(UserContext)


    const [profilePicture, setProfilePicture] = useState("")
    const [bannerPicture, setBannerPicture] = useState("")
    const [bio, setBio] = useState("")

    const [saveAnimation, setSaveAnimation] = useState(false)
    function savingAnimation() {
        setSaveAnimation(true)
        axios.post(`https://thekeyboardserver.herokuapp.com/users/${username}/setProfilePictures`, {
            username: username,
            profilePicture: profilePicture,
            profileBanner: bannerPicture,
            bio: bio,
        })

        setTimeout(() => setSaveAnimation(false), 3000);

    }

    const uploadProfile = async (e) => {
        const targetFile = e.target.files[0]
        const base64 = await convertFile(targetFile)
        setProfilePicture(base64)
    }

    const convertFile = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file)

            fileReader.onload = () => {
                resolve(fileReader.result)
            }
            fileReader.onerror = (error) => {
                reject(error);
            }
        });
    }
    const uploadBanner = async (e) => {
        const targetFile = e.target.files[0]
        const base64 = await convertFile(targetFile)
        setBannerPicture(base64)
    }

    return (
        <div className='text-mono text-indigo-500 text-lg mt-10 text-indigo-200 overflow-x-hidden overflow-y-auto'>
            <div className={`transform absolute top-0 right-0 mr-10 bg-indigo-600 text-indigo-100 p-3 rounded-b-xl shadow-lg translate ${saveAnimation ? "-translate-y-0" : "-translate-y-full"} duration-75 transition-transform`}>
                Saving Changes
            </div>
            <div className="w-128 h-1 my-5 rounded-full bg-indigo-900 opacity-30"></div>
            <h1 className='text-indigo-700 text-xl'>Profile picture:</h1>
            <label htmlFor="" className='mr-5'>Upload a profile picture</label><input type="file" onChange={(e) => uploadProfile(e)} />
            <div className="w-128 h-1 my-5 rounded-full bg-indigo-900 opacity-30"></div>
            <h1 className='text-indigo-700 text-xl'>Banner picture:</h1>
            <label htmlFor="" className='mr-5'>Upload a banner picture</label><input type="file" onChange={(e) => uploadBanner(e)} />
            <div className="w-128 h-1 my-5 rounded-full bg-indigo-900 opacity-30"></div>
            <h1 className='text-indigo-700 text-xl'>Bio: {bio.length}/150</h1>
            <textarea name="" placeholder='Type your bio here...' id="" className=' p-2 focus:border bg-transparent rounded-lg outline-none resize-none' maxLength="150" cols="30" rows="10" value={bio} onChange={(e) => setBio(e.target.value)}></textarea>
            <div className="w-128 h-1 my-5 rounded-full bg-indigo-900 opacity-30"></div>

            <button className='bottom-0 absolute right-0 text-purple-200 px-8 py-3 shadow-md hover:shadow-lg hover:bg-indigo-700 focus:ring-indigo-600 focus:ring-2 bg-indigo-800 m-10 rounded-lg' onClick={() => savingAnimation()}>Save</button>

        </div>
    )
}

export default AccountSettings
