import './App.css';
import React, { useEffect, useState, useContext, useMemo, createContext } from 'react';
import axios from 'axios';
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom'
import PostPage from './Pages/PostPage';
import HomePage from './Pages/HomePage';
import CreatePage from './Pages/CreatePage';
import Navbar from './Components/Navbar';
import SignupPage from './Pages/SignupPage';
import LoginPage from './Pages/LoginPage';
import { UserContext } from './UserContext';
import {username} from './UsernameContext';
import ProfilePage from './Pages/ProfilePage';
import SettingsPage from './Pages/SettingsPage';
import AppearanceSettings from './Components/AppearanceSettings';
import AccountSettings from './Components/AccountSettings';
import FollowerPage from './Pages/FollowerPage';
import FollowingPage from './Pages/FollowingPage';

function App() {

  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [username, setUsername] = useState("")
  const [userSettings, setUserSettings] = useState(
    
      {
      catPaw: false,
      catEmoji: false,
      tooManyCats: false
    }
  
  )

  return (
    <BrowserRouter>
      <UserContext.Provider value={{isLoggedIn, setIsLoggedIn, username, setUsername, setUserSettings, userSettings}}> 

        <Navbar />
        
        <Routes>
          
          <Route path="/" exact element={<HomePage />} />
          <Route path="/posts/:id" element={<PostPage />} />
          <Route path="/create" element={<CreatePage />} />
          <Route path="/sign-up" element={<SignupPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/user" exact element={<ProfilePage />} />
          <Route path="/user/:user" element={<ProfilePage />} />
          <Route path="/user/:user/followers" element={<FollowerPage />} />
          <Route path="/user/:user/following" element={<FollowingPage />} />
          <Route path="/setting"exact element={<SettingsPage />} >
            <Route path="/setting/appearance" element={<AppearanceSettings />} />
            <Route path="/setting/account" element={<AccountSettings />} />
          </Route>


        </Routes>

      </UserContext.Provider>
    </BrowserRouter>
  );
}

export default App;
