import React from 'react'
import { useState, useContext } from 'react'
import { UserContext } from '../UserContext'
import axios from 'axios'
function AppearanceSettings() {

    const { isLoggedIn, setIsLoggedIn, username, setUsername, userSettings, setUserSettings } = useContext(UserContext)

    const [saveAnimation, setSaveAnimation] = useState(false)
    const [catPaw, setCatPaw] = useState(false)
    const [catEmoji, setCatEmoji] = useState(false)


    function savingAnimation() {
        setSaveAnimation(true)
        setTimeout(() => setSaveAnimation(false), 3000);
        setUserSettings({ catPaw: catPaw, catEmoji: catEmoji })
        console.log(userSettings.catPaw)
    }

    function catPawSetting(e) {
        setCatPaw(!catPaw)
        console.log(catPaw)
    }

    function catEmojiSetting(e) {
        setCatEmoji(!catEmoji)
        console.log(catEmoji)
    }


    return (
        <div className='text-mono text-indigo-500 text-lg mt-10 overflow-x-hidden overflow-y-auto'>
            <div className={`transform absolute top-0 right-0 mr-10 bg-indigo-600 text-indigo-100 p-3 rounded-b-xl shadow-lg translate ${saveAnimation ? "-translate-y-0" : "-translate-y-full"} duration-75 transition-transform`}>
                Saving Changes
            </div>
            <div className="w-128 h-1 my-5 rounded-full bg-indigo-900 opacity-30"></div>
            <h1 className='text-indigo-700 text-xl'>Cats:</h1>
            <label htmlFor="">Cat paw gif - </label><input className="" type="checkbox" checked={catPaw} onChange={() => catPawSetting()} /><br />
            <label htmlFor="">Cat instead of keyboard in logo - </label><input className="" type="checkbox" checked={catEmoji} onChange={() => catEmojiSetting()} /><br />
            <label htmlFor="">Too many cats 🙀 - </label><input className="" type="checkbox" value="" />
            <div className="w-128 h-1 my-5 rounded-full bg-indigo-900 opacity-30"></div>
            <button className='bottom-0 absolute right-0 text-purple-200 px-8 py-3 shadow-md hover:shadow-lg hover:bg-indigo-700 focus:ring-indigo-600 focus:ring-2 bg-indigo-800 m-10 rounded-lg' onClick={() => savingAnimation()}>Save</button>
        </div>
    )
}

export default AppearanceSettings
