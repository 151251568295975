import React, { useEffect, useState, useContext } from 'react'
import { useParams, Link } from 'react-router-dom'
import axios from 'axios'
import ProfilePostThumb from '../Components/ProfilePostThumb'
import { UserContext } from '../UserContext'

function ProfilePage() {
    const handle = useParams()
    const { user } = useParams();
    const { isLoggedIn, setIsLoggedIn, username, setUsername } = useContext(UserContext)
    const [isFollowing, setIsFollowing] = useState()
    const [profileUser, setProfileUser] = useState([])
    const [validUser, setValidUser] = useState(false)
    const [userPosts, setUserPosts] = useState([])
    const [loggedInUser, setLoggedInUser] = useState("")
    const [followCount, setFollowCount] = useState()
    const [loadingFollowers, setLoadingFollowers] = useState(false)
    useEffect(() => {
        getUser()
        console.log(user)
        console.log(username)

    }, [user])

    async function getUser() {
        await axios.get(`https://thekeyboardserver.herokuapp.com/users/${username}`)
            .then(res => {
                const subs = res.data[0];
                setLoggedInUser(subs)
                //console.log(profileUser._id)
                // setIsFollowing()

            })
        axios.get(`https://thekeyboardserver.herokuapp.com/users/${user}`)
            .then(res => {
                const subs = res.data;
                setProfileUser(res.data[0])
                setValidUser(true)
                console.log(loggedInUser._id)
                if (res.data.length === 0) {
                    setValidUser(false)
                } else {
                    // 
                    setFollowCount(res.data[0].followers.length)
                    console.log(res.data[0].followers.includes(loggedInUser._id))
                    setIsFollowing(res.data[0].followers.includes(username))

                    checkFollowing()
                    setValidUser(true)
                    getPosts(profileUser)
                    // console.log(profileUser.bio)

                }
            })
    }

    function checkFollowing() {
        console.log("yooo")
        console.log(profileUser)
    }

    function getPosts(profileUser) {
        axios.get(`https://thekeyboardserver.herokuapp.com/subscribers/posts/${user}`)
            .then(res => {
                const subs = res.data;
                setUserPosts(subs)
            })
    }

    async function follow() {
        // setIsFollowing(loggedInUser.following?.includes(profileUser._id))
        if (loadingFollowers) {
            return
        } else {
            if (isFollowing) {
                setIsFollowing(false)
                console.log("unfollowing")
                setFollowCount(followCount - 1)
                setLoadingFollowers(true)
                await axios.post(`https://thekeyboardserver.herokuapp.com/users/unfollow`, {
                    follower: username,
                    followed: profileUser.username
                })
                    .then(res => {
                        const subs = res.data[0];
                        console.log(subs)
                        console.log(loggedInUser.following?.includes(profileUser._id))
                        setLoadingFollowers(false)
                    })
            } else {
                console.log("following")
                setIsFollowing(true)
                setFollowCount(followCount + 1)
                setLoadingFollowers(true)

                await axios.post(`https://thekeyboardserver.herokuapp.com/users/follow`, {
                    follower: username,
                    followed: profileUser.username
                })
                    .then(res => {
                        const subs = res.data[0];
                        console.log(subs)
                        console.log(loggedInUser.following?.includes(profileUser._id))
                        setLoadingFollowers(false)
                    })

            }


        }
    }


    return (
        <div className='flex justify-center items-center h-screen font-mono text-indigo-200'>
            <div className="bg-gray-900 sm:w-8/12 w-10/12 sm:h-4/6 h-5/6 rounded-3xl overflow-hidden shadow-xl sm:mt-0 mt-10">
                {
                    validUser ? (
                        <div className=" h-full">
                            <div style={{ backgroundImage: `url('${profileUser?.profileBanner}')`, backgroundPosition: "center", backgroundSize: 'cover' }} className={`${profileUser?.profileBanner ? " " : "bg-indigo-900"} w-full h-40 flex sm:flex-row flex-col items-start sm:items-center p-3 sm:justify-between`}>
                                <div className="shadow-lg sm:ml-10 sm:mb-0 mb-2 bg-gray-800 px-2 py-2 rounded-full flex items-center">
                                    <div style={{ backgroundImage: `url('${profileUser?.profilePicture}')`, backgroundPosition: "center", backgroundSize: 'cover' }} className={`${profileUser?.profilePicture ? " " : "bg-gray-900"} h-14 w-14 rounded-full shadow-md overflow-hidden flex justify-center items-center`}>
                                        {/* <img src={profileUser?.profilePicture} alt="" /> */}
                                    </div>
                                    <h1 className='text-xl ml-4 mr-5'>{
                                        validUser ? (
                                            "@" + profileUser?.username
                                        ) : (
                                            "NOT FOUND"
                                        )
                                    }
                                    </h1>
                                </div>
                                {
                                    username == user || !isLoggedIn ? (
                                        <div className="">
                                            {/* <button className='mr-10 bg-indigo-900 px-7 py-3 rounded-lg shadow-md' disabled>You can't follow yourself </button> */}
                                        </div>
                                    ) : (
                                        <div className="">
                                            {
                                                isFollowing ?
                                                    (
                                                        <button className='mr-10 bg-indigo-900 opacity-75 px-7 py-3 rounded-full border border-indigo-600 hover:border-indigo-400 text-indigo-50 select-none' onClick={() => follow()}>Unfollow</button>
                                                    ) :
                                                    (
                                                        <button className='mr-10 bg-indigo-600 px-7 py-3 opacity-90 rounded-full  shadow-md border border-indigo-300 hover:border-indigo-100 text-indigo-50 select-none' onClick={() => follow()}>Follow</button>
                                                    )
                                            }
                                        </div>
                                    )
                                }


                            </div>

                            <div className="p-4 pt-5">
                                <div className="flex  pb-2">
                                    <ul className="flex sm:flex-row justify-center w-full px-5 text-indigo-500">
                                        <div className="w-40 sm:w-128 text-indigo-600">
                                            {
                                                validUser && (
                                                    `Bio:
                                             ${profileUser?.bio === undefined ? "This user has not set a bio!" : profileUser?.bio}`
                                                )
                                            }
                                        </div>
                                        <div className="flex sm:flex-row flex-col">
                                            <Link to={`followers`} className=''><li className='mx-3 '>Followers: {followCount}</li></Link>
                                            <Link to={`following`}><li className='mx-3'>Following: {profileUser?.following?.length}</li></Link>
                                        </div>

                                    </ul>
                                </div>
                            </div>
                            {
                                userPosts != null ? (
                                    <div className="overflow-y-scroll h-3/6 scrollbar-thin scrollbar-thumb-indigo-500 scrollbar-track-transparent hover:scrollbar-thumb-indigo-600 ">

                                        <ul className='flex flex-col items-center'>
                                            <h1 className='fixed'>{userPosts.length} {userPosts.length > 1 ? "posts" : "post"} by <b className='text-indigo-500'>@{profileUser?.username}</b>:</h1>
                                            {
                                                userPosts.slice(0).reverse().map((item, i) => (
                                                    <ProfilePostThumb likeCount={item.usersLiked.length} image={item.image} key={item._id} postId={item._id} postTitle={item.name} postSubTitle={item.lastName} author={item.author} date={item.subscribeDate} />

                                                ))
                                            }
                                        </ul>
                                    </div>
                                ) : (
                                    <div className="overflow-y-scroll h-35">

                                        <h1>Loading stil!</h1>
                                    </div>
                                )
                            }

                        </div>
                    ) : (
                        <div className="flex flex-col items-center w-full justify-center h-full bg-red-900 opacity-50 transition-all duration-75">
                            <h1 className='text-red-300 text-2xl text-center'>USER "{user}" <br /> NOT FOUND</h1>
                            <Link to="/"><h1 className='text-red-100 shadow-md text-2xl px-5 py-3 mt-4 rounded-full text-center bg-red-700'>Go Back</h1></Link>
                        </div>
                    )
                }

            </div>

        </div>
    )
}

export default ProfilePage
