import React from 'react'
import { Link } from 'react-router-dom'

function ProfilePostThumb(props) {
    return (
        <Link key={props.postId} className="m-3 bg-gray-800 w-2/3 focus:outline-none focus:ring focus:ring-indigo-500  rounded-xl p-3 shadow-lg overflow-hidden transition duration-200" to={`/posts/${props.postId}`}>
            <li className='md:flex-row w-full flex flex-col justify-between'>
                <div className="">
                    <h2 className="text-xl text-indigo-300">{props.postTitle}</h2>
                    <h3 className='text-indigo-500'>{props.postSubTitle}</h3>
                    <small className=" text-right	text-l text-indigo-300 underline">@{props.author}</small> <br />
                    <i className="text-indigo-600 mt-3  far fa-heart fa-1x"></i> <small className='text-indigo-600'>Likes: {props.likeCount}</small>
                </div>
                <div style={{ backgroundImage: `url('${props.image}')`, backgroundPosition: "center", backgroundSize: 'cover' }} className="rounded-xl w-40 h-32"></div>
            </li>
        </Link>
    )
}

export default ProfilePostThumb
