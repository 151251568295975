import React from 'react'
import { Link } from 'react-router-dom'

function FollowersThumb(props) {
    return (
        <div style={{ backgroundImage: `url('${props.banner}')`, backgroundPosition: "center", backgroundSize: 'cover' }} key={props.username} className='hover:shadow-xl transform transition-all duration-200 h-50 w-full bg-gray-800 p-4 my-2 shadow-lg rounded-xl'>
            <Link to={`/user/${props.username}`} className='group'>
                <div className="shadow-lg ml-1 w-min bg-gray-900 px-2 py-2 rounded-full flex items-center backdrop-blur-sm">
                    <div style={{ backgroundImage: `url('${props.profile}')`, backgroundPosition: "center", backgroundSize: 'cover' }} className={`bg-gray-900 h-14 w-14 rounded-full ring-2 ring-indigo-500 shadow-md overflow-hidden flex justify-center items-center`}>
                        {/* <img src={profileUser?.profilePicture} alt="" /> */}
                    </div>
                    <h1 className='text-xl ml-4 mr-5 group-hover:text-indigo-500'>{
                        props.username ? (
                            "@" + props.username
                        ) : (
                            "NOT FOUND"
                        )
                    }
                    </h1>
                </div>
            </Link>
        </div>
    )
}

export default FollowersThumb
