import React, {useState, useEffect, useContext} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import axios from 'axios';
import { UserContext } from '../UserContext';
import { Username } from '../UsernameContext';

function LoginPage() {
  let navigate = useNavigate();
  const { isLoggedIn, setIsLoggedIn, username, setUsername} = useContext(UserContext)

  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")

  function handleLogin () {
      if (email == null ) {
        console.log("fill in email!")

      } else{
        axios.post('https://thekeyboardserver.herokuapp.com/users/login', {
          email: email,
          password: password
        })
          .then(function (response){
            if(response === "wrong"){
              setEmail("")
              setPassword("")
              setIsLoggedIn(false)
              console.log("wrong password!")
            } else {
              setIsLoggedIn(true)
              setUsername(response.data)
              setEmail("")
              setPassword("")
              navigate("/")
              console.log("logged in!")
              console.log(response.data)

              
            }

          })
          .catch(function (error) {
            console.log(error);
          });

      }

      


  }
    return (
        <div>
        <div className="h-screen  flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8 font-mono">
        <div className="max-w-md w-full space-y-8 bg-gray-900 px-6 pb-8 rounded-lg shadow-md">
          <div>
            <h2 className="mt-6 text-center text-3xl font-extrabold text-indigo-500">Log in now!</h2>
            <p className="mt-2 text-center text-sm text-gray-600">
              Or{' '}
              <Link to="/sign-up" className="font-medium text-indigo-600 hover:text-indigo-500">
                sign up if you don't already have an account
              </Link>
            </p>
          </div>
          <form className="mt-8 space-y-6" action="#" method="POST">
            <input type="hidden" name="remember" defaultValue="true" />
            <div className="rounded-md shadow-sm -space-y-px">

              <div>
                <label htmlFor="email-address" className="sr-only">
                  Email address
                </label>
                <input
                  value={email} 
                  onChange={(e) => setEmail(e.target.value)}
                  id="email-address"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  className="w-full focus:outline-none focus:ring-2  mb-3 focus:ring-indigo-600 focus:bg-gray-900 shadow rounded-md bg-gray-800 text-indigo-300 p-2"
                  placeholder="Email address"
                />
              </div>
              <div>
                <label htmlFor="password" className="sr-only">
                  Password
                </label>
                <input
                  value={password} 
                  onChange={(e) => setPassword(e.target.value)}
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                className="w-full focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:bg-gray-900 shadow rounded-md bg-gray-800 text-indigo-300 p-2"
                  placeholder="Password"
                />
              </div>
            </div>

           

            <div>
              <button
                onClick={handleLogin}
                type='button'
                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                </span>
                Sign in
              </button>
            </div>
          </form>
        </div>
      </div>
        </div>
)
}

export default LoginPage