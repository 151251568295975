import React, { useEffect, useState, useContext } from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import { Link } from 'react-router-dom'
import { UserContext } from '../UserContext'

function PostPage() {
    const handle = useParams()
    const [postInfo, setPostInfo] = useState([])
    const { isLoggedIn, setIsLoggedIn, username, setUsername } = useContext(UserContext)
    const [userId, setUserId] = useState()
    const [likeButton, setLikeButton] = useState("far")

    useEffect(async () => {
        await axios.get(`https://thekeyboardserver.herokuapp.com/subscribers/${handle.id}`)
            .then(res => {
                const subs = res.data;
                setPostInfo(subs)
                console.log(postInfo)

            })
        console.log(handle.id)
        if (isLoggedIn) {
            await axios.get(`https://thekeyboardserver.herokuapp.com/users/${username}`)
                .then(res => {
                    const subs = res.data;
                    setUserId(subs[0]._id)
                })

        } else {
            console.log("login first")
            return

        }

    }, [])




    async function likePost() {
        if (postInfo.usersLiked?.includes(userId)) {
            if (userId != undefined) {
                await axios.post(`https://thekeyboardserver.herokuapp.com/subscribers/${handle.id}/dislike/${userId}`)
                    .then(res => {
                        const subs = res.data;
                        console.log(res.data)
                    })

            } else {
                console.log("ERROR")
                return
            }
        } else {
            if (userId != undefined) {
                await axios.post(`https://thekeyboardserver.herokuapp.com/subscribers/${handle.id}/like/${userId}`)
                    .then(res => {
                        const subs = res.data;
                        console.log(res.data)
                    })

            } else {
                console.log("ERROR")
                return
            }
        }

        refreshContent()
    }

    function refreshContent() {
        axios.get(`https://thekeyboardserver.herokuapp.com/subscribers/${handle.id}`)
            .then(res => {
                const subs = res.data;
                setPostInfo(subs)
            })
    }

    return (
        <div className='h-auto bg-gray-800'>
            <div className="flex h-auto">
                <div className="w-screen bg-gray-800 min-h-screen flex justify-center items-center">
                    <div className="md:h-max h-auto md:mt-0 mt-24 font-mono flex-col bg-gray-900 rounded-xl p-10 shadow-md w-3/4  md:w-1/2">
                        <img src={postInfo.image} className='max-h-96 mb-5' alt="" />
                        <h1 className="text-4xl text-indigo-200 font-mono">{postInfo.name}</h1>
                        <h2 className="text-2xl text-indigo-400 font-mono">{postInfo.lastName}</h2>
                        <h2 className="text-1xl text-indigo-500 underline font-mono"><Link className='w-min' to={`/user/${postInfo.author}`}>@{postInfo.author}</Link></h2>

                        <p className="text-indigo-600 mt-4">{postInfo.desc}</p>
                        {
                            postInfo.usersLiked?.includes(userId) ? (
                                <i onClick={likePost} className={`text-indigo-600 mt-3 fas fa-heart fa-1x`}></i>
                            ) : (
                                <i onClick={likePost} className={`text-indigo-600 mt-3  far fa-heart fa-1x`}></i>
                            )
                        }
                        <small className='ml-2 text-indigo-600 select-none'>Likes: {postInfo.usersLiked?.length}</small>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PostPage
