import React, { useEffect, useState, useContext } from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import { Link, useNavigate } from 'react-router-dom'
import { UserContext } from '../UserContext'
function CreatePage() {

    const { isLoggedIn, setIsLoggedIn, username, setUsername } = useContext(UserContext)

    let navigate = useNavigate();

    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [desc, setDesc] = useState("")
    const [file, setFile] = useState("")
    function handleSending() {
        if (firstName != null) {
            const data = new FormData()
            data.append('image', file)
            console.log(file)
            // fetch('http://localhost:3000/subscribers/upload', {
            //     method: "POST",
            //     body: data,
            // })
            //     .then(function (response) {
            //         console.log(response);
            //     })
            //     .catch(function (error) {
            //         console.log(error);
            //     });
            axios.post('https://thekeyboardserver.herokuapp.com/subscribers', {
                name: firstName,
                lastName: lastName,
                desc: desc,
                author: username,
                image: file,
                likes: 0,
            })
                .then(function (response) {
                    console.log(response);
                })
                .catch(function (error) {
                    console.log(error);
                });
            setFirstName(" ")
            setLastName(" ")
            setDesc(" ")
            navigate("/");

        }


    }

    const uploadImage = async (e) => {
        const targetFile = e.target.files[0]
        const base64 = await convertFile(targetFile)
        setFile(base64)
    }

    const convertFile = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file)

            fileReader.onload = () => {
                resolve(fileReader.result)
            }
            fileReader.onerror = (error) => {
                reject(error);
            }
        });
    }

    return (
        <div className="h-screen w-screen bg-gray-800">
            <div className="flex h-screen w-screen items-center justify-center content-center" >
                {
                    isLoggedIn ? (
                        <div className="rounded-md flex-col flex bg-gray-900 p-6 text-2xl font-mono text-indigo-300 shadow-md">
                            <label htmlFor="">Title</label>
                            <input type="text" value={firstName} onChange={(e) => setFirstName(e.target.value)
                            } className="focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:bg-gray-900 shadow rounded-md bg-gray-800 text-indigo-00 p-2" />
                            <label htmlFor="" className='' >Subtitle </label>
                            <input type="text" value={lastName} onChange={(e) => setLastName(e.target.value)} className="focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:bg-gray-900 shadow rounded-md bg-gray-800 text-indigo-300 p-2" />
                            <label >Description: </label>
                            <textarea type="text" value={desc} onChange={(e) => setDesc(e.target.value)} className="focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:bg-gray-900 shadow rounded-md bg-gray-800 text-indigo-300 p-2" />
                            <input type='file' className=' block mt-5 file:bg-violet-300 text-sm' onChange={(e) => uploadImage(e)} />

                            <button className='mt-5 bg-indigo-900 text-indigo-400 rounded-md shadow-md p-2 px-10 hover:bg-indigo-700 hover:text-indigo-200' onClick={handleSending}>Post</button>
                        </div>
                    ) : (
                        <div className='rounded-md flex-col flex bg-gray-900 p-6 text-2xl font-mono text-indigo-300 shadow-md'>
                            <Link to="/"><h1 className='text-center text-red-600'>Please log in first! <br />(click to go back)</h1></Link>
                        </div>
                    )
                }

            </div >
            <style>

            </style>
        </div >
    )
}

export default CreatePage
