import React from 'react'
import { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom'
import axios from 'axios';
import FollowersThumb from '../Components/FollowersThumb';

function FollowingPage() {
    const { user } = useParams();
    const [followers, setFollowers] = useState([])

    useEffect(() => {
        axios.get(`https://thekeyboardserver.herokuapp.com/users/${user}/following`)
            .then(res => {
                const subs = res.data;
                setFollowers(subs)
                console.log(subs)
            })
    }, [])

    return (
        <div className="h-screen font-mono w-screen bg-gray-800 flex justify-center items-center text-indigo-200">
            <div className='bg-gray-900 w-128 mx-5 h-4/6 rounded-3xl overflow-hidden shadow-xl p-10'>
                <h1 className='text-2xl text-center '><span className='text-indigo-500 font-bold'><Link to={`/user/${user}`}>@{user}</Link></span> is following </h1>
                <div className="flex-col flex justify-center w-auto pt-3 h-full overflow-y-auto overflow-x-hidden">
                    {followers.slice(0).reverse().map((returned) => (
                        <FollowersThumb username={returned.username} banner={returned.profileBanner} profile={returned.profilePicture} />
                    ))}

                </div>
            </div>
        </div>
    )
}

export default FollowingPage
