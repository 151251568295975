import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom'
import HomePostThumb from '../Components/HomePostThumb';
import { UserContext } from '../UserContext'

function HomePage() {
  const [subscribedTo, setSubscribedTo] = useState("")
  const [lastName, setLastName] = useState("")
  const [firstName, setFirstName] = useState("")
  const [returnedData, setReturnedData] = useState([])
  const [postId, setPostId] = useState("")
  const [feedType, setFeedType] = useState(true)
  const { isLoggedIn, setIsLoggedIn, username, setUsername, userSettings } = useContext(UserContext)
  const [loadingPosts, setLoadingPosts] = useState(false)
  useEffect(() => {
    getPosts()
    // setTimeout(getPosts, 100);
  }, [])

  async function getPosts() {
    if (loadingPosts == false) {
      setLoadingPosts(true)
      axios.get(`https://thekeyboardserver.herokuapp.com/subscribers`)
        .then(res => {
          const subs = res.data;
          setReturnedData(subs)
          console.log(subs)
          setLoadingPosts(false)
        })
        .catch(function (error) {
          setLoadingPosts(false)

        })
    }
  }

  async function getFollowedPosts() {
    if (loadingPosts == false) {
      setLoadingPosts(true)
      axios.get(`https://thekeyboardserver.herokuapp.com/subscribers/followed/${username}`)
        .then(res => {
          const subs = res.data;
          setReturnedData(subs)
          console.log(subs)
          setLoadingPosts(false)
        })
        .catch(function (error) {
          setLoadingPosts(false)

        })
    }

  }

  function handleSending() {

    axios.post('https://thekeyboardserver.herokuapp.com/subscribers/', {
      name: firstName,
      subscriberToChannel: subscribedTo,
      lastName: lastName
    })
      .then(function (response) {
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });
    setSubscribedTo(" ")
    setLastName(" ")
    setFirstName(" ")
  }
  function handleDelete() {

    axios.delete(`https://thekeyboardserver.herokuapp.com/subscribers/${postId}`, {})
      .then(function (response) {
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });
    setPostId(" ")
  }

  function changeFeed() {

    if (loadingPosts === false) {
      setFeedType(!feedType)
      if (feedType) {
        getFollowedPosts()
      } else {
        getPosts()

      }
    } else {
      console.log("Slow down please")
    }
  }

  return (
    <div className="App scroll-smooth">
      <div className="bg-gray-800 h-auto pb-20  text-blue-500 font-mono scroll-smooth ">
        <ul className="list-none flex flex-col justify-center items-center pt-32 scroll-smooth ">
          {
            isLoggedIn &&
            <div className=" flex justify-start w-64  md:w-128  ">
              <button onClick={() => changeFeed()} className={`${feedType ? "bg-indigo-800 text-gray-900" : "bg-gray-900 text-indigo-600"} shadow-md bg-gray-900 px-5 py-2 rounded-l-full border-4 border-r-2 font-extrabold hover:text-gray-900 border-gray-900  hover:bg-indigo-500 transition duration-150`}>New</button><button onClick={() => changeFeed()} className={`${feedType ? "bg-gray-900 text-indigo-600" : "bg-indigo-800 text-gray-900"} font-extrabold transition duration-150 hover:bg-indigo-500 hover:text-gray-900 shadow-md border-4 border-gray-900 rounded-r-full border-l-2 bg-gray-900 px-5 py-2`}>Followed</button>
            </div>
          }

          <Link to="/create" className="group cursor-pointer  text-xl shadow bg-gray-900 m-2 rounded-md p-4 w-64  hover:shadow-lg hover:bg-indigo-900 hover:text-indigo-200 md:w-128 ">
            <li className='group-hover:text-white text-indigo-600' >+ New Post</li>
          </Link>
          {returnedData.slice(0).reverse().map((returned) => (
            <HomePostThumb likeCount={returned.usersLiked} image={returned.image} key={returned._id} postId={returned._id} postTitle={returned.name} postSubTitle={returned.lastName} author={returned.author} date={returned.subscribeDate} />
          ))}
        </ul>
        <div className="hidden">
          <label htmlFor="">Title</label>
          <input type="text" value={firstName} onChange={(e) => setFirstName(e.target.value)} className="firstNameInput" />
          <label htmlFor="" >Subtitle </label>
          <input type="text" value={lastName} onChange={(e) => setLastName(e.target.value)} className="lastNameInput" />
          <label htmlFor="" >Subscribed to: </label>
          <input type="text" value={subscribedTo} onChange={(e) => setSubscribedTo(e.target.value)} className="subscribedInput" />
          <button onClick={handleSending}>Send</button>
          <input type="text" value={postId} onChange={(e) => setPostId(e.target.value)} className="subscribedInput" />
          <button onClick={handleDelete}>Send Delete</button>
        </div>

      </div>
    </div>
  )
}

export default HomePage
