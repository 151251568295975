import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useContext, useState } from 'react'
import { UserContext } from '../UserContext'

function Navbar() {
    let navigate = useNavigate();

    const { isLoggedIn, setIsLoggedIn, username, setUsername, userSettings } = useContext(UserContext)
    const [menuOpen, setMenuOpen] = useState(false)

    function signOut() {
        setIsLoggedIn(false)
        navigate("/");
        window.location.reload(false);
    }

    return (
        <div className='font-mono'>
            {
                isLoggedIn ? (
                    <div className="top-0 fixed right-0 p-5 mt-6 z-30">
                        <small className='text-indigo-100 text-md bg-gray-900   py-3 px-4 cursor-pointer select-none rounded-full shadow-md hover:text-indigo-400' onClick={() => setMenuOpen(!menuOpen)}>@{username}</small>
                        <div className={` shadow-md z-10 transition-all duration-300 w-32 ${menuOpen ? "visible" : "hidden"}  bg-gray-900 rounded-xl -ml-10 mt-4 ring-1 ring-offset-indigo-100 text-indigo-200  absolute`}>
                            <ul className='p-3 pt-2 w-full text-right flex-col justify-evenly' onClick={() => setMenuOpen(!menuOpen)}>
                                <Link to={`/user/${username}`} className='hover:underline hover:text-indigo-400'><li className='flex justify-between'><i className="fas fa-user"></i>Profile</li></Link>
                                <Link to={`/setting`} className='hover:underline hover:text-indigo-400'><li className='flex justify-between '><i className="fas fa-cog"></i>Settings</li></Link>
                                <Link to={`/`} onClick={() => signOut()} className='hover:underline hover:text-indigo-400'><li className='flex justify-between'><i className="fas fa-sign-out-alt"></i>Sign Out</li></Link>
                            </ul>
                        </div>
                    </div>
                ) : (
                    <div className="top-0 absolute sm:fixed mt-5 right-0 p-5 z-30">
                        <Link to="/login"><button className='bg-indigo-800  text-indigo-100 py-3 px-7 text-xs sm:text-base rounded-lg shadow-md hover:bg-indigo-600 '>Login or Sign up</button></Link>
                    </div>
                )
            }
            <div className='fixed z-50 whitespace-nowrap bg-indigo-700 w-screen flex content-center justify-center'><small className='text-indigo-300'>YOU MAY NEED TO REFRESH TO VIEW NEW POSTS </small></div>
            <Link to="/" > <h1 className="text-2xl z-30 sm:text-4xl  text-blue-100 bg-none font-mono p-10 fixed">{`The KeyBoard! ${userSettings.catEmoji ? "=^._.^=" : "⌨"}`}</h1></Link>
            {
                userSettings.catPaw && (
                    <img src="https://c.tenor.com/2HoHEwGqRz4AAAAi/cats.gif" className='fixed bottom-0 h-16' alt="" />
                )
            }
        </div>
    )
}

export default Navbar
