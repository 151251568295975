import React from 'react'
import { Link } from 'react-router-dom'
import { useContext, useState, useEffect } from 'react'
import { UserContext } from '../UserContext'
import axios from 'axios'

function HomePostThumb(props) {

    const { isLoggedIn, setIsLoggedIn, username, setUsername } = useContext(UserContext)
    const [hasLiked, setHasLiked] = useState("far")


    useEffect(() => {
        if (username && isLoggedIn) {
            axios.get(`https://thekeyboardserver.herokuapp.com/users/${username}`)
                .then(res => {
                    const subs = res.data;
                    if (props.likeCount.includes(subs[0]._id)) {
                        setHasLiked("fas")
                    }
                })
        }
    }, [])
    return (
        <Link key={props.postId} className=" hover:border border-none z-10 hover:border-indigo-500 focus:outline-none focus:ring focus:ring-indigo-500 transform focus:scale-110  hover:scale-110  text-xl shadow bg-gray-900 m-2 rounded-md p-4 w-64 hover:shadow-lg  md:w-128 transition duration-200 overflow-hidden" to={`/posts/${props.postId}`}>
            <li className='md:flex-row flex flex-col justify-between'>
                <div className="">
                    <h2 className="text-2xl text-indigo-300">{props.postTitle}</h2>
                    <h3 className='text-indigo-500'>{props.postSubTitle}</h3>
                    <small className=" text-right	text-l text-indigo-300 underline">@{props.author}</small> <br />
                    <i className={`text-indigo-600 mt-3 ${hasLiked} fa-heart fa-1x`}></i> <small className='text-indigo-600'>Likes: {props.likeCount.length}</small>
                </div>
                <div style={{ backgroundImage: `url('${props.image}')`, backgroundPosition: "center", backgroundSize: 'cover' }} className="rounded-xl w-32 h-32"></div>
            </li>
        </Link>
    )
}

export default HomePostThumb
