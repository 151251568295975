import React from 'react'
import { useState, useEffect, useContext } from 'react'
import { NavLink, useParams, Routes, Route, useLocation, useRoutes, Link, BrowserRouter } from 'react-router-dom'
import AccountSettings from '../Components/AccountSettings'
import GeneralSettings from '../Components/GeneralSettings'
import AppearanceSettings from '../Components/AppearanceSettings'
import { UserContext } from '../UserContext'

function SettingsPage() {
    const handle = useParams()
    const location = useLocation()
    console.log(handle)
    const [categorie, setCategorie] = useState('General')

    const { isLoggedIn, setIsLoggedIn, username, setUsername } = useContext(UserContext)



    return (
        <div className="flex h-auto font-mono">
            <div className='w-screen min-h-screen flex justify-center items-center'>

                {
                    isLoggedIn ? (
                        <div className="relative bg-gray-900 h-5/6 md:h-2/3 w-11/12 md:w-2/3 rounded-3xl shadow-lg p-10 overflow-x-auto scrollbar-thin scrollbar-thumb-indigo-500 scrollbar-track-transparent hover:scrollbar-thumb-indigo-600 ">
                            <h1 className='text-indigo-600 text-6xl'>Settings</h1>
                            <h2 className='text-indigo-800 text-2xl mb-5'>{categorie}</h2>
                            <ul className='flex text-indigo-300 w-80 justify-between'>
                                <NavLink
                                    to="/setting"
                                    onClick={() => setCategorie("General")}
                                >
                                    General
                                </NavLink>
                                <NavLink
                                    to={`/setting/appearance`}
                                    onClick={() => setCategorie("Appearance")}
                                >
                                    Appearance
                                </NavLink>
                                <NavLink
                                    to={`/setting/account`}
                                    onClick={() => setCategorie("Account")}
                                >
                                    Account
                                </NavLink>
                            </ul>
                            <Routes>
                                <Route path="/" exact element={<GeneralSettings />} />
                                <Route path="/appearance" element={<AppearanceSettings />} />
                                <Route path="/account" element={<AccountSettings />} />
                            </Routes>
                        </div>
                    ) : (
                        <div className="bg-gray-900 rounded-lg shadow-lg mx-5 p-10 flex justify-center items-center ">
                            <h1 className='text-red-600 text-3xl select-none'>You shouldn't be here bud 👿</h1>
                        </div>
                    )
                }


            </div>

        </div >

    )
}

export default SettingsPage
