import React from 'react'

function GeneralSettings() {
    return (
        <div className='text-mono text-indigo-500 text-lg mt-10 text-indigo-200 overflow-y-auto'>
            <h1>No settings here yet...</h1>
        </div>
    )
}

export default GeneralSettings
